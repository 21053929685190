// import SanityBlockContent from "@sanity/block-content-to-react"
import React from "react"
// import { HeartIcon } from "@heroicons/react/outline"
// import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/new-design/Layout"
import { ListingDescCard, ImgCard, HeadCard } from "../components/template"
import Seo from "../components/seo"
export default function Page({ location, pageContext: { data } }) {
  // console.log(data)
  const metaImage = {
    src: data.image?.asset?.url,
    width: data.image?.asset?.metadata?.dimensions?.width,
    height: data.image?.asset?.metadata?.dimensions?.height,
  }
  return (
    <Layout>
      <Seo
        template
        image={metaImage}
        description={data.shortDescription}
        title={data.title || data.name}
        pathname={location.pathname}
        //   schemaMarkup={schema}
        //    description={property.highlight}
      />
      <div className="bg-white border-t pb-6">
        <HeadCard data={data} />
        <ImgCard property={data} />
      </div>
      <ListingDescCard data={data} />
      {/* <Booking /> */}
    </Layout>
  )
}
